import getConfig from 'next/config'

export function urlEncodeObject(object: Record<string, any>) {
  return Object.entries(object)
    .map(
      ([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`,
    )
    .join('&')
}

export function getAvatarSrc(userId: string) {
  const { publicRuntimeConfig: env } = getConfig()
  return `${env.apiBaseURL}/user-images/profile_picture/${userId}?w=140&h=140`
}
