export const PAYMENT_STATUSES = {
  PAID: 'paid',
  CLAIMED: 'claimed',
  CLAIMED_BY_EMAIL: 'claimed_by_email',
  CLAIMED_BY_FPS: 'claimed_by_fps',
  CLAIMED_BY_FPS_LOADING: 'claimed_by_fps_loading',
  FAILED: 'failed',
  EXPIRED: 'expired',
  CANCELLED: 'cancelled',
  UNCLAIMED: 'unclaimed',
} as const

export type PaymentStatus =
  (typeof PAYMENT_STATUSES)[keyof typeof PAYMENT_STATUSES]

export type Referral = {
  title: string
  description: string
  legal: string
  button: string
  button_url: string
} | null

export type Payment = {
  id: string
  amount: number
  currency: string
  fps_enabled: boolean
  notes: string
  sender_full_name: string
  sender_user_id: string
  claim_type: string
  status: PaymentStatus
  signup_bonus: number
  referral: Referral
  display_complete_page: boolean
  has_added_email: boolean
}
