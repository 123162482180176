import cx from 'classnames'
import * as React from 'react'

import type { Colors, TextColorVariables } from '../shared-types'
import Spacer from '../spacer'
import { SpacingProps } from '../spacer/spacer'
import styles from './icon.module.scss'
import { SterlingsignBubble } from './icons'
import AccountManagement from './icons/account-management'
import ActivityLeading from './icons/activity-leading'
import Add from './icons/add'
import AddPerson from './icons/add-person'
import Airplane from './icons/airplane'
import Alert from './icons/alert'
import AngledArrow from './icons/angled-arrow'
import Archive from './icons/archive'
import ArrowDown from './icons/arrow-down'
import ArrowLeft from './icons/arrow-left'
import ArrowRight from './icons/arrow-right'
import ArrowRotating from './icons/arrow-rotating'
import ArrowUpArrowDown from './icons/arrow-up-arrow-down'
import ArrowsSwap from './icons/arrows-swap'
import Avatar from './icons/avatar'
import BackArrow from './icons/back-arrow'
import Bank from './icons/bank'
import BarChart from './icons/bar-chart'
import Book from './icons/book'
import Borrowing from './icons/borrowing'
import Briefcase from './icons/briefcase'
import BusinessBanking from './icons/business-banking'
import Calendar from './icons/calendar'
import CallSettings from './icons/call-settings'
import Card from './icons/card'
import CashAtm from './icons/cash-atm'
import ChartBarLine from './icons/chart-bar-line'
import ChatCategorization from './icons/chat-categorization'
import ChatForm from './icons/chat-form'
import Checkmark from './icons/checkmark'
import CheckmarkCircle from './icons/checkmark-circle'
import ChevronDoubleLeft from './icons/chevron-double-left'
import ChevronDoubleRight from './icons/chevron-double-right'
import ChevronDown from './icons/chevron-down'
import ChevronLeft from './icons/chevron-left'
import ChevronRight from './icons/chevron-right'
import ChevronUp from './icons/chevron-up'
import CircleAdd from './icons/circle-add'
import CircleCheckmark from './icons/circle-checkmark'
import CircleClose from './icons/circle-close'
import Clock from './icons/clock'
import Close from './icons/close'
import Complaints from './icons/complaints'
import ConfirmationTick from './icons/confirmation-tick'
import Contactless from './icons/contactles'
import Contentful from './icons/contentful'
import Copy from './icons/copy'
import Cross from './icons/cross'
import CustomerProblems from './icons/customer-problems'
import CustomerWithPhone from './icons/customer-with-phone'
import DirectionalLines from './icons/directional-lines'
import Disputes from './icons/disputes'
import Document from './icons/document'
import DocumentCsv from './icons/document-csv'
import Download from './icons/download'
import DynamicFormBuilder from './icons/dynamic-form-builder'
import Edit from './icons/edit'
import Email from './icons/email'
import EmailRouting from './icons/email-routing'
import ErrorIcon from './icons/error'
import ExclamationMark from './icons/exclamation-mark'
import Export from './icons/export'
import Eye from './icons/eye'
import EyeSlash from './icons/eye-slash'
import FileCsv from './icons/file-csv'
import FilePdf from './icons/file-pdf'
import FileQif from './icons/file-qif'
import Filter from './icons/filter'
import FinancialDifficulties from './icons/financial-difficulties'
import Fincrime from './icons/fincrime'
import FinCrimeBusinessBanking from './icons/fincrime-business-banking'
import Fraud from './icons/fraud'
import Gift from './icons/gift'
import Github from './icons/github'
import Globe from './icons/globe'
import Heart from './icons/heart'
import HeartOutline from './icons/heart-outline'
import HiBob from './icons/hibob'
import HourglassFilled from './icons/hourglass-filled'
import House from './icons/house'
import Info from './icons/info'
import InternalMonzoProjects from './icons/internal-monzo-projects'
import Investments from './icons/investments'
import Laptop from './icons/laptop'
import LineChart from './icons/line-chart'
import Link from './icons/link'
import List from './icons/list'
import LoadingSpinner from './icons/loading-spinner'
import Lock from './icons/lock'
import Looker from './icons/looker'
import LookerColor from './icons/looker-color'
import Message from './icons/message'
import Minus from './icons/minus'
import MoneyCash from './icons/money-cash'
import MoneyCheque from './icons/money-cheque'
import MonzoM from './icons/monzo-m'
import MonzoMRefreshed from './icons/monzo-m-refreshed'
import Navigation from './icons/navigation'
import NavigationMenu from './icons/navigation-menu'
import NonInteractiveTime from './icons/non-interactive-time'
import Opening from './icons/opening'
import Paid from './icons/paid'
import PaperPlane from './icons/paper-plane'
import Payments from './icons/payments'
import Percentage from './icons/percentage'
import Person2 from './icons/person-2'
import PersonCheck from './icons/person-check'
import PersonCircles from './icons/person-circles'
import Phone from './icons/phone'
import PhoneAndLaptop from './icons/phone-and-laptop'
import Photo from './icons/photo'
import Pot from './icons/pot'
import ProductLevers from './icons/product-levers'
import Puzzle from './icons/puzzle'
import Qa from './icons/qa'
import QrCode from './icons/qr-code'
import QuestionMark from './icons/question-mark'
import RealtimeOverview from './icons/realtime-overview'
import Receipt from './icons/receipt'
import ReceiptSplit from './icons/receipt-split'
import Reorder from './icons/reorder'
import Retail from './icons/retail'
import Rewards from './icons/rewards'
import Savings from './icons/savings'
import Search from './icons/search'
import Settings from './icons/settings'
import Shield from './icons/shield'
import Shuffle from './icons/shuffle'
import Slack from './icons/slack'
import Slas from './icons/slas'
import Snowflake from './icons/snowflake'
import Social from './icons/social'
import Sparkles from './icons/sparkles'
import SquadCaptain from './icons/squad-captain'
import Star from './icons/star-fill'
import StarPlus from './icons/star-plus'
import StarStroke from './icons/star-stroke'
import SterlingEnvelope from './icons/sterling-envelope'
import SubscriptionSparkles from './icons/subscription-sparkles'
import Support from './icons/support'
import Swap from './icons/swap'
import Tag from './icons/tag'
import Task from './icons/task'
import TaskCompleted from './icons/task-completed'
import TaskTypes from './icons/task-types'
import TaskViews from './icons/task-views'
import TestTube from './icons/test-tube'
import ThumbsDown from './icons/thumbs-down'
import ThumbsUp from './icons/thumbs-up'
import Tick from './icons/tick'
import Toy from './icons/toy'
import Trash from './icons/trash'
import Us from './icons/us'
import VulnerableCustomers from './icons/vulnerable-customers'
import WindowAndIcons from './icons/window-and-icons'
import Workflows from './icons/workflows'
import WorkflowsShapes from './icons/workflows-shapes'
import WorkforceAttributes from './icons/workforce-attributes'

// Please keep this list in alphabetical order so icons are easier to find in Storybook
export const ICONS = Object.freeze({
  'account-management': AccountManagement,
  'activity-leading': ActivityLeading,
  add: Add,
  'add-person': AddPerson,
  airplane: Airplane,
  alert: Alert,
  'angled-arrow': AngledArrow,
  archive: Archive,
  'arrow-down': ArrowDown,
  'arrow-left': ArrowLeft,
  'arrow-right': ArrowRight,
  'arrows-swap': ArrowsSwap,
  'arrows-up-arrow-down': ArrowUpArrowDown,
  'arrow-rotating': ArrowRotating,
  avatar: Avatar,
  'back-arrow': BackArrow,
  bank: Bank,
  'bar-chart': BarChart,
  book: Book,
  borrowing: Borrowing,
  briefcase: Briefcase,
  'business-banking': BusinessBanking,
  calendar: Calendar,
  'call-settings': CallSettings,
  card: Card,
  'cash-atm': CashAtm,
  'chart-bar-line': ChartBarLine,
  'chat-categorization': ChatCategorization,
  'chat-form': ChatForm,
  checkmark: Checkmark,
  'checkmark-circle': CheckmarkCircle,
  'chevron-double-left': ChevronDoubleLeft,
  'chevron-double-right': ChevronDoubleRight,
  'chevron-down': ChevronDown,
  'chevron-left': ChevronLeft,
  'chevron-right': ChevronRight,
  'chevron-up': ChevronUp,
  'circle-add': CircleAdd,
  'circle-checkmark': CircleCheckmark,
  'circle-close': CircleClose,
  close: Close,
  complaints: Complaints,
  'confirmation-tick': ConfirmationTick,
  contactless: Contactless,
  contentful: Contentful,
  copy: Copy,
  cross: Cross,
  'customer-problems': CustomerProblems,
  'customer-with-phone': CustomerWithPhone,
  'directional-lines': DirectionalLines,
  disputes: Disputes,
  document: Document,
  'document-csv': DocumentCsv,
  download: Download,
  'dynamic-form-builder': DynamicFormBuilder,
  edit: Edit,
  email: Email,
  'email-routing': EmailRouting,
  'exclamation-mark': ExclamationMark,
  error: ErrorIcon,
  export: Export,
  eye: Eye,
  'eye-slash': EyeSlash,
  'file-csv': FileCsv,
  'file-pdf': FilePdf,
  'file-qif': FileQif,
  filter: Filter,
  fincrime: Fincrime,
  'fincrime-business-banking': FinCrimeBusinessBanking,
  'financial-difficulties': FinancialDifficulties,
  fraud: Fraud,
  gift: Gift,
  github: Github,
  globe: Globe,
  heart: Heart,
  'heart-outline': HeartOutline,
  'hourglass-filled': HourglassFilled,
  hibob: HiBob,
  house: House,
  info: Info,
  'internal-monzo-projects': InternalMonzoProjects,
  investments: Investments,
  laptop: Laptop,
  link: Link,
  clock: Clock,
  list: List,
  lock: Lock,
  looker: Looker,
  'looker-color': LookerColor,
  'line-chart': LineChart,
  'loading-spinner': LoadingSpinner,
  message: Message,
  minus: Minus,
  'money-cash': MoneyCash,
  'money-cheque': MoneyCheque,
  'monzo-m': MonzoM,
  'monzo-m-refreshed': MonzoMRefreshed,
  'navigation-menu': NavigationMenu,
  navigation: Navigation,
  'non-interactive-time': NonInteractiveTime,
  opening: Opening,
  paid: Paid,
  'paper-plane': PaperPlane,
  payments: Payments,
  percentage: Percentage,
  'person-2': Person2,
  'person-check': PersonCheck,
  'person-circles': PersonCircles,
  phone: Phone,
  'phone-and-laptop': PhoneAndLaptop,
  photo: Photo,
  pot: Pot,
  'product-levers': ProductLevers,
  puzzle: Puzzle,
  qa: Qa,
  'qr-code': QrCode,
  'question-mark': QuestionMark,
  'realtime-overview': RealtimeOverview,
  receipt: Receipt,
  'receipt-split': ReceiptSplit,
  reorder: Reorder,
  retail: Retail,
  rewards: Rewards,
  savings: Savings,
  search: Search,
  settings: Settings,
  shield: Shield,
  shuffle: Shuffle,
  slack: Slack,
  slas: Slas,
  snowflake: Snowflake,
  social: Social,
  sparkles: Sparkles,
  star: Star,
  'star-plus': StarPlus,
  'star-stroke': StarStroke,
  'sterling-envelope': SterlingEnvelope,
  'sterlingsign-bubble': SterlingsignBubble,
  support: Support,
  swap: Swap,
  'squad-captain': SquadCaptain,
  'subscriptions-sparkle': SubscriptionSparkles,
  tag: Tag,
  task: Task,
  'task-completed': TaskCompleted,
  'task-types': TaskTypes,
  'task-views': TaskViews,
  'test-tube': TestTube,
  'thumbs-down': ThumbsDown,
  'thumbs-up': ThumbsUp,
  tick: Tick,
  toy: Toy,
  trash: Trash,
  us: Us,
  'vulnerable-customers': VulnerableCustomers,
  'window-and-icons': WindowAndIcons,
  workflows: Workflows,
  'workflows-shapes': WorkflowsShapes,
  'workforce-attributes': WorkforceAttributes,
})

export type Icons = keyof typeof ICONS
export type IconSizes =
  | 12
  | 14
  | 16
  | 18
  | 20
  | 24
  | 32
  | 36
  | 40
  | 48
  | 64
  | 80

export type Props = {
  icon: Icons
  size: IconSizes
  className?: string
  color?: 'currentColor' | TextColorVariables | Colors
  fill?: string
} & SpacingProps

export default function Icon(props: Props) {
  const {
    size = 24,
    icon,
    className,
    color = 'currentColor',
    ...otherProps
  } = props

  const _classNames = cx(
    {
      [styles.root]: true,
      [styles[`color-${color}`]]: true,
    },

    className,
  )

  const SVGIcon = ICONS[icon]

  return (
    <Spacer
      {...otherProps}
      is={SVGIcon}
      className={_classNames}
      width={size}
      height={size}
    />
  )
}
