import * as React from 'react'
import { SVGProps } from 'react'
const SvgToy = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22.6023 1.396L22.7237 1.52821C23.0674 1.93659 23.228 2.44672 23.2055 2.95038C22.9112 7.03918 20.842 10.2647 18.0614 13.2191C18.7427 16.6307 16.7124 20.6162 13.1435 22.5379L12.8282 22.7003L12.5116 22.8487L12.4547 22.8724L12.3481 22.9102C11.9216 23.037 11.5037 22.8715 11.3543 22.4858C11.2549 22.2292 11.2958 21.9305 11.443 21.6725C12.2279 20.4412 12.7717 19.0989 13.0308 17.742C11.7284 18.6358 10.3243 19.3234 8.72171 19.6755L8.31688 19.7565L4.24114 15.6807C4.56082 13.9095 5.28622 12.3801 6.25332 10.9701C4.89906 11.2301 3.55915 11.7734 2.32977 12.5571C2.07177 12.7043 1.77304 12.7452 1.51644 12.6458C1.13078 12.4964 0.965277 12.0785 1.09206 11.652L1.12989 11.5454L1.15362 11.4885C1.24789 11.2771 1.35142 11.0663 1.46435 10.8566C3.38494 7.28974 7.36704 5.25971 10.7773 5.93757C13.7324 3.15595 16.9581 1.08639 21.0472 0.792123C21.5509 0.769563 22.061 0.930173 22.4694 1.2739L22.6023 1.396ZM13.8464 10.1525C13.0654 9.3715 11.799 9.3715 11.018 10.1525C10.2369 10.9336 10.2369 12.1999 11.018 12.9809C11.799 13.762 13.0654 13.762 13.8464 12.9809C14.6274 12.1999 14.6274 10.9336 13.8464 10.1525Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgToy
