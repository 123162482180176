import { Error } from '../types/error'
import {
  PAYMENT_STATUSES,
  type Payment,
  type PaymentStatus,
} from '../types/payment'
import { apiRequest } from './auth'
import { setAPICallError } from './errors'
import mondoApi from './mondoSdk'

export const formatAmount = (payment: Payment): string => {
  const pounds = payment.amount / 100
  if (!pounds || isNaN(pounds)) {
    return `0.00`
  }

  return `${pounds.toFixed(2)}`
}

export const paymentCompleted = (status: PaymentStatus) =>
  status !== PAYMENT_STATUSES.UNCLAIMED &&
  status !== PAYMENT_STATUSES.CLAIMED_BY_FPS_LOADING

//Still needed for control variant
export const paymentCompletedOld = (status: PaymentStatus) =>
  status !== PAYMENT_STATUSES.UNCLAIMED

export const fetchPayment = async (
  code: string,
  setStatus: (status: PaymentStatus) => void,
  setPayment: (payment: Payment) => void,
  setErrors: (errors: Error) => void,
): Promise<Payment | undefined> => {
  try {
    const response = await apiRequest(mondoApi.fetchPayment, code)
    setStatus(response.body.status)
    setPayment(response.body)
    return response.body
  } catch (e) {
    const error = e as globalThis.Error
    await setAPICallError(error, setErrors)
    return undefined
  }
}

export const claimByFPS = async (
  code: string,
  sortCode: string,
  accountNumber: string,
  name: string,
  email: string,
  setStatus: (status: PaymentStatus) => void,
  setErrors: (errors: Error) => void,
): Promise<void> => {
  try {
    await apiRequest(
      mondoApi.claimPaymentByFPS,
      code,
      sortCode,
      accountNumber,
      name,
      email,
    )
    setStatus('claimed_by_fps_loading')
  } catch (e) {
    const error = e as globalThis.Error
    await setAPICallError(error, setErrors)
    throw error
  }
}

export const confirmFPS = async (
  code: string,
  secondsWaited: number,
  setStatus: (status: PaymentStatus) => void,
  setErrors: (errors: Error) => void,
): Promise<void> => {
  if (secondsWaited > 10) {
    setStatus('claimed_by_fps')
    return
  }
  try {
    const response = await apiRequest(mondoApi.fetchPayment, code)
    switch (response.body.status) {
      case 'failed':
        setStatus('failed')
        break
      case 'paid':
        setStatus('claimed_by_fps')
        break
      default:
        await new Promise((resolve) => setTimeout(resolve, 2000))
        await confirmFPS(code, secondsWaited + 2, setStatus, setErrors)
    }
  } catch (e) {
    const error = e as globalThis.Error
    return await setAPICallError(error, setErrors)
  }
}

export const claimByEmail = async (
  code: string | null,
  email: string,
  setStatus: (status: PaymentStatus) => void,
  setErrors: (error: Error) => void,
): Promise<void> => {
  try {
    await apiRequest(mondoApi.claimPaymentByEmail, code || '', email)
    setStatus('claimed_by_email')
  } catch (e) {
    const error = e as globalThis.Error
    return await setAPICallError(error, setErrors)
  }
}
