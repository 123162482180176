import * as React from 'react'
import { SVGProps } from 'react'
const SvgCircleAdd = (props: SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    fill="none"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C15.5229 20 20 15.5229 20 10C20 4.47705 15.5229 0 10 0C4.47705 0 0 4.47705 0 10C0 15.5229 4.47705 20 10 20ZM9 6C9 5.44775 9.44775 5 10 5C10.5522 5 11 5.44775 11 6V9H14C14.5522 9 15 9.44775 15 10C15 10.5522 14.5522 11 14 11H11V14C11 14.5522 10.5522 15 10 15C9.44775 15 9 14.5522 9 14V11H6C5.44775 11 5 10.5522 5 10C5 9.44775 5.44775 9 6 9H9V6Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgCircleAdd
