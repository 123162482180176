import 'what-input'

import '../styles/index.scss'

// Required for setting CSP headers in getInitialProps in _document
import { NextPageContext } from 'next'
import { AppProps } from 'next/app'
import getConfig from 'next/config'
import Head from 'next/head'
import Router from 'next/router'
import React from 'react'
import ReactGA from 'react-ga'

import { MonzoUiThemeProvider, Theme } from '@mondough/monzo-ui'
import { initErrorHandling } from '@mondough/next-utils'
import { displayExternalSelfXSSWarning } from '@mondough/self-xss-warning'

import { AppContextProvider } from '../AppContext'
import { initializeServerSideLogging } from '../utils/customLog'

const { publicRuntimeConfig: env } = getConfig()

const ignoredErrors = [
  // Chrome/Edge/Samsung Browser
  'Network error: Failed to fetch',
  'TypeError: Failed to fetch',
  'ApolloError: Failed to fetch',
  // Safari:
  'Network error: Load failed',
  'TypeError: Load failed',
  'ApolloError: Load failed',
  // Firefox:
  'Network error: NetworkError when attempting to fetch resource.',
  'TypeError: NetworkError when attempting to fetch resource.',
  'ApolloError: NetworkError when attempting to fetch resource.',

  // Expected 401 errors (https://www.notion.so/monzo/Reducing-noise-in-Sentry-from-401-API-errors-ebbba831d898407a8a288201c5ded12f):
  'Access token has been evicted',
  'Access token has expired',
  'User authentication required',

  //Expected error from Next router
  'Abort fetching component for route',
]

function App({ Component, pageProps }: AppProps) {
  React.useEffect(() => {
    // Set Google Analytics key
    ReactGA.initialize(env.googleAnalyticsTrackingId)
    initErrorHandling(undefined, ignoredErrors, 'app.pay-anyone')
    const recordPageview = (path: string) => {
      ReactGA.set({ page: path })
      ReactGA.pageview(path)
    }
    Router.events.on('routeChangeComplete', recordPageview)
    recordPageview(window.location.pathname)
  }, [])

  initializeServerSideLogging()

  if (typeof window !== 'undefined') {
    displayExternalSelfXSSWarning()
  }

  return (
    <AppContextProvider>
      <Head>
        <title>Receive money using Monzo</title>
        <meta
          name="viewport"
          content="width=device-width, height=device-height, initial-scale=1, maximum-scale=5"
        />
      </Head>
      <MonzoUiThemeProvider initialTheme={Theme.LightRefreshed}>
        <Component {...pageProps} />
      </MonzoUiThemeProvider>
    </AppContextProvider>
  )
}

App.getInitialProps = async ({
  Component,
  ctx,
}: {
  Component: AppProps['Component']
  ctx: NextPageContext
}) => {
  let pageProps = {}
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx)
  }

  return { pageProps }
}

export default App
