import * as React from 'react'
import { SVGProps } from 'react'
const SvgHourglassFilled = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M8 22c-1.1 0-2-.9-2-2l.01-3.18c0-.53.21-1.03.58-1.41L10 12 6.59 8.57c-.37-.37-.58-.88-.58-1.41L6 4c0-1.1.9-2 2-2h8c1.1 0 2 .9 2 2v3.16c0 .53-.21 1.04-.58 1.41L14 12l3.41 3.4c.38.38.59.89.59 1.42V20c0 1.1-.9 2-2 2H8Zm8-14.91V5c0-.55-.45-1-1-1H9c-.55 0-1 .45-1 1v2.09c0 .27.11.52.29.71L12 11.5l3.71-3.71c.18-.18.29-.44.29-.7Z"
    />
  </svg>
)
export default SvgHourglassFilled
