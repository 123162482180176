import * as React from 'react'
import { SVGProps } from 'react'
const SvgClock = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2Zm0 2a1 1 0 0 0-1 1v7a1 1 0 0 0 1 1h5a1 1 0 1 0 0-2h-4V5a1 1 0 0 0-1-1Z"
    />
  </svg>
)
export default SvgClock
