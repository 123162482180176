import * as React from 'react'
import { SVGProps } from 'react'
const SvgExclamationMark = (props: SVGProps<SVGSVGElement>) => (
  <svg {...props} viewBox="0 0 24 24">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="currentColor"
      d="M12 2a1.5 1.5 0 0 0-1.5 1.5v11a1.5 1.5 0 0 0 3 0v-11A1.5 1.5 0 0 0 12 2ZM12 21.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
    />
  </svg>
)
export default SvgExclamationMark
